import { Col, Row, Typography, Space, Button } from 'antd'
import kukkis from './videot/kukkis.mp4'
import poika from './kuvat/poika.jpg'
import hissiliput from './kuvat/hissiliput.jpg'
import r1 from './kuvat/Ruosniemi_1b.jpg'
import r2 from './kuvat/Ruosniemi_2b.jpg'
import r5 from './kuvat/Ruosniemi_5.jpg'
import r8 from './kuvat/Ruosniemi_8.jpg'
import r9 from './kuvat/Ruosniemi_9.jpg'
import r7 from './kuvat/Ruosniemi_7.jpg'
import vuokraamo from './kuvat/Ruosniemi-26.jpg'
import parkki from './kuvat/Ruosniemi-35b.jpg'
import hiihtokoulu from './kuvat/poikab.jpg'
import ratalasku from './kuvat/Ruosniemi_5b.jpg'
import sukset from './kuvat/sukset.jpg'
import './App.css'
import korona from './kuvat/korona.jpg'
import { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import axios from 'axios'
import Paivystysvuorot from './components/hiihtotunnit/Paivystysvuorot'
import ProvideAuth from './components/auth/ProvideAuth'
import HiihtotunnitOpe from './components/hiihtotunnit/HiihtitunnitOpe'
import Ostatunti from './components/hiihtotunnit/OstaTunti'
import {url} from './components/constants'

const { Text } = Typography


const App = () => {

  const [w, setW] = useState()
  const [user, setUser] = useState(null)

  useEffect(() => {
    axios(url + '/auth')
      .then(res => setUser(res.data))
  })

  useEffect(() => {
    if (window.innerWidth < 600) {
      setW(350)
    } else {
      setW(500)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 600) {
        setW(350)
      } else {
        setW(500)
      }
    })
  })

  useEffect(() => {
    window.addEventListener('resize', resizeHanlder)
  })

  const resizeHanlder = () => {
    //window.location = "/"
  }

  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <Route exact path="/">
            <section className="stacking-slide-video">
              <div class="video-container">
                <video autoPlay loop muted playsInline width="1280" height="720">
                  <source src={kukkis} type="video/mp4" />
                </video>
                {/* <div className="overlay">
                  {user && user === 'no' &&
                    <div style={{ margin: 30 }}>
                      <Button style={{ margin: 30 }} size='large' type='primary'><Link to="/paivystys">Päivystysvuorot</Link></Button>
                      <Button style={{ margin: 30 }} size='large' type='primary'><Link to="/hiihtotunnitope">Hiihtotunnit</Link></Button>
                    </div>
                  }
                </div> */}
              </div>
            </section>

            {/* AUKIOLOT JA KORONA PALKKI */}
            <Row justify="center" gutter={[8, 8]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                <div className="textsection" style={{ height: '100%', textAlign: 'center', marginTop: '1vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Space direction="vertical" align="center">
                    <Text>Aukiolot</Text>
                    <Text>Maanantaisin suljettu</Text>
                    <Text>ti-pe 17-20</Text>
                    <Text>la-su 10-18</Text>
                  </Space>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                <div style={{ textAlign: 'center' }}>
                  <img width="100%" src={korona} />
                </div>
              </Col>
            </Row>

            {/* HISSILIPUT PALKKI */}
            <section className="stacking-slide" style={{ backgroundColor: "white" }}>
              <div style={{ flexShrink: 0, backgroundImage: `url(${hissiliput})`, width: '100%', height: '70vh', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', opacity: '100%' }}>
                <div className="overlay">
                  <a style={{ position: 'absolute', top: '30vh' }} href="something" class="button1">HISSILIPUT</a>
                </div>
              </div>
            </section>

            {/* YHTEYSTIEDOT JA FACEBOOK PALKKI */}
            <Row justify="center">
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="textsection" style={{ height: '100%', textAlign: 'center', marginTop: '1vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Space direction="vertical" align="center">
                    <Text>Yhteystiedot</Text>
                    <Text>Puhelin: 040 99009900</Text>
                    <Text>Sähköposti: kukkis@kukkis.fi</Text>
                  </Space>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div style={{ textAlign: 'center', margin: '20px 0px' }}>
                  <div class="fb-page" data-href="https://www.facebook.com/Ruosniemen-Hiihtokeskus-138335259651428" data-tabs="timeline" data-width={w} data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/Ruosniemen-Hiihtokeskus-138335259651428" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Ruosniemen-Hiihtokeskus-138335259651428">Ruosniemen Hiihtokeskus</a></blockquote></div>
                </div>
              </Col>
            </Row>


            <Row gutter={[{ xs: 2, sm: 4, md: 4}, { xs: 2, sm: 4, md: 4}]}>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <div style={{ flexShrink: 0, backgroundImage: `url(${vuokraamo})`, width: '100%', height: '60vh', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', opacity: '100%' }}>
                  <div className="overlay">
                    <a style={{ position: 'absolute', top: '30vh' }} href="something" class="button1">VUOKRAAMO</a>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <div style={{ flexShrink: 0, backgroundImage: `url(${hiihtokoulu})`, width: '100%', height: '60vh', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', opacity: '100%' }}>
                  <div className="overlay">
                    <Link style={{ position: 'absolute', top: '30vh' }} to="/hiihtokoulu" class="button1">HIIHTOKOULU</Link>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <div style={{ flexShrink: 0, backgroundImage: `url(${parkki})`, width: '100%', height: '60vh', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', opacity: '100%' }}>
                  <div className="overlay">
                    <a style={{ position: 'absolute', top: '30vh' }} href="something" class="button1">PARKKI</a>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <div style={{ flexShrink: 0, backgroundImage: `url(${ratalasku})`, width: '100%', height: '60vh', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', opacity: '100%' }}>
                  <div className="overlay">
                    <a style={{ position: 'absolute', top: '30vh' }} href="something" class="button1">RATALASKU</a>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <div style={{ flexShrink: 0, backgroundImage: `url(${r8})`, width: '100%', height: '60vh', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', opacity: '100%' }}>
                  <div className="overlay">
                    <a style={{ position: 'absolute', top: '30vh' }} href="something" class="button1">YHDISTYS</a>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <div style={{ flexShrink: 0, backgroundImage: `url(${r9})`, width: '100%', height: '60vh', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', opacity: '100%' }}>
                  <div className="overlay">
                    <a style={{ position: 'absolute', top: '30vh' }} href="something" class="button1">SKI.FI</a>
                  </div>
                </div>
              </Col>
            </Row>

            {/*  <section className="stacking-slide" style={{ backgroundColor: "white" }}>
                <div style={{ flexShrink: 0, backgroundImage: `url(${sukset})`, width: '100%', height: '70vh', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                  <div className="overlay">
                    <a style={{ position: 'absolute', top: '40vh', color: 'white', border: '0.1em solid white' }} href="something" class="button1">VÄLINEVUOKRAUS</a>
                  </div>
                </div>
              </section> */
              }
          </Route>

          <Route exact path="/paivystys">
            <Paivystysvuorot />
          </Route>
          <Route exact path="/hiihtotunnitope">
            <HiihtotunnitOpe />
          </Route>
          <Route exact path="/hiihtokoulu">
            <Ostatunti />
          </Route>
        </Switch>
      </Router>
    </ProvideAuth>
  )
}

export default App;
