import { Calendar, Card, Col, Row, Form, Select, Button, List, Typography, Badge, Radio, Switch, Breadcrumb } from "antd";
import { MinusCircleOutlined, PlusOutlined, LeftCircleOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import 'moment/locale/fi'  // without this line it didn't work
import moment from 'moment'
import { ConfigProvider } from 'antd';
import fi_FI from 'antd/lib/locale/fi_FI'
import axios from "axios";
import { url } from "../constants";
import {
    BrowserRouter as Router,
    Route,
    Link
} from "react-router-dom";
import { useAuth } from '../auth/Auth'

moment.locale('fi_FI')


const { Option } = Select;
const { Text } = Typography

const Paivystysvuorot = () => {
    const [date, setDate] = useState(moment())
    const [vuorot, setVuorot] = useState([{ pvm: "2021-2-22", alkaa: '10:30', paattyy: '13:30', opettaja: { nimi: 'Pasi', id: 1 } }])
    const [omatVuorot, setOmatVuorot] = useState([])
    const [vapaatVuorot, setVapaatVuorot] = useState([])
    const [paivanVuorot, setpaivanVuorot] = useState([])
    const [form] = Form.useForm();
    const [defaultStart, setDefaultStart] = useState()
    const [defaultEnd, setDefaultEnd] = useState()
    const [opettajat, setOpettajat] = useState([])
    const [showCal, setShowCal] = useState(true)
    const [isDesktop, setIsDesktop] = useState(false)
    const [omat, setOmat] = useState(true)

    const auth = useAuth();

    const paivita = async () => {
        const data = await axios(url + "/paivystysvuoro/")
        setVuorot(data.data)
        setpaivanVuorot(
            data.data.filter(vuoro => date.format("YYYY-MM-DD") === (vuoro.pvm))
        )
        const omatvuorot = await axios(url + '/omatvuorot')
        setOmatVuorot(omatvuorot.data)
        console.log('omat: ', omatvuorot.data)
        const vapaat = await axios(url + '/vapaatvuorot')
        setVapaatVuorot(vapaat.data)
        console.log('vapaat: ', vapaat.data)
    }

    const onDateChange = (data) => {
        setDate(data)
    }

    const poistaVuoro = async (i) => {
        await axios.delete(url + '/paivystysvuoro/' + i)
        paivita()
    }

    const lisaaOpe = async (ope, vuoro) => {
        if (ope === 'poista') {
            await axios(url + "/poistaopettajavuorosta/" + vuoro)
            paivita()
        } else {
            await axios(url + "/lisaavuoroonopettaja/" + vuoro + "/" + ope)
            paivita()
        }
    }

    const otavuoro = async (vuoro, id) => {
        await axios(url + '/lisaavuoroonopettaja/' + vuoro.id + "/" + id)
        paivita()
    }

    const onSelect = (d) => {
        moment(date).year() === moment(d).year() && moment(date).month() === moment(d).month() && setShowCal(false)
    }

    const dateCellRender = (value) => {
        let vuorotKalenterissa = null
        if (!omat) {
            vuorotKalenterissa = vuorot.filter(vuoro =>
                moment(vuoro.pvm).date() === value.date() && moment(vuoro.pvm).month() === value.month() && moment(vuoro.pvm).year() === value.year()
            ).sort(function (a, b) {
                return (a.opettaja != null) - (b.opettaja != null) || a - b;
            })

        } else {
            vuorotKalenterissa = [...omatVuorot, ...vapaatVuorot].filter(vuoro =>
                moment(vuoro.pvm).date() === value.date() && moment(vuoro.pvm).month() === value.month() && moment(vuoro.pvm).year() === value.year()
            ).sort(function (a, b) {
                return (a.opettaja != null) - (b.opettaja != null) || a - b;
            })
        }

        return (
            <ul style={{ padding: 0 }}>
                {vuorotKalenterissa.map(vuoro => (
                    <li style={{ listStyle: 'none' }}>
                        {
                            <div >
                                <Badge color={!vuoro.opettaja ? 'green' : 'red'} text={isDesktop && (vuoro.opettaja ? vuoro.opettaja.nimi + ": " + vuoro.alkaa + " - " + vuoro.paattyy : 'Ei opettajaa')} />
                            </div>
                        }
                    </li>
                ))}
            </ul>
        )
    }

    const dateCellRenderOmat = (value) => {
        const vuorotKalenterissa = [...omatVuorot, ...vapaatVuorot].filter(vuoro =>
            moment(vuoro.pvm).date() === value.date() && moment(vuoro.pvm).month() === value.month() && moment(vuoro.pvm).year() === value.year()
        ).sort(function (a, b) {
            return (a.opettaja != null) - (b.opettaja != null) || a - b;
        })

        return (
            <ul>
                {vuorotKalenterissa.map(vuoro => (
                    <li style={{ listStyle: 'none' }}>
                        {
                            <Badge color={!vuoro.opettaja ? 'green' : 'red'} />
                        }
                    </li>
                ))}
            </ul>
        )
    }

    useEffect(async () => {
        const data = await axios(url + '/opettajat')
        setOpettajat(data.data)
    }, [])

    useEffect(() => {
        (window.innerWidth > 800) ? setIsDesktop(true) : setIsDesktop(false)
    })

    const reziseHandler = () => {
        (window.innerWidth > 800) ? setIsDesktop(true) : setIsDesktop(false)
    }

    useEffect(() => {
        window.addEventListener('resize',
            reziseHandler
        )
    }, [])

    useEffect(async () => {
        paivita()
        console.log(auth && auth.user)
        auth && auth.user && auth.user.roles && auth.user.roles.includes('hiihtoadmin') && setOmat(false)
    }, [])

    useEffect(() => {
        ((date.weekday() < 5) ? (form.setFieldsValue({ alkaa: "17:30" }) || form.setFieldsValue({ paattyy: "20:30" }) || form.setFieldsValue({ opettajaId: "" })) : (form.setFieldsValue({ alkaa: "10:30" }) || form.setFieldsValue({ paattyy: "13:30" }) || form.setFieldsValue({ opettajaId: "" })))
    })

    useEffect(() => {
        setpaivanVuorot(
            vuorot.filter(vuoro => date.format("YYYY-MM-DD") === (vuoro.pvm))
        )
    }, [date])

    const onFinish = async (values) => {
        console.log('Success:', { ...values, date: date.format("YYYY-M-DD") });
        await axios.post(url + "/paivystysvuoro/", { ...values, pvm: date.format("YYYY-MM-DD") })
        paivita()
    };

    return (
        <ConfigProvider locale={fi_FI}>
            <div style={{ flex: '1 0 auto' }}>

                {auth && auth.user && auth.user.roles && auth.user.roles.includes('opettaja') && <p>opettaja</p>}

                <Button type="primary"><Link to="/"><LeftCircleOutlined /> Takaisin</Link></Button>
                <Row>
                    {showCal && <Col span={24} style={{ padding: '5px 0px' }}>
                        <Calendar
                            onSelect={(d) => onSelect(d)}
                            dateCellRender={dateCellRender}
                            onChange={onDateChange}

                            headerRender={({ value, onChange, }) => {
                                const start = 0;
                                const end = 12;
                                const monthOptions = [];

                                const current = value.clone();
                                const localeData = value.localeData();
                                const months = [];
                                for (let i = 0; i < 12; i++) {
                                    current.month(i);
                                    months.push(localeData.monthsShort(current));
                                }

                                for (let index = start; index < end; index++) {
                                    monthOptions.push(
                                        <Select.Option className="month-item" key={`${index}`}>
                                            {months[index]}
                                        </Select.Option>,
                                    );
                                }
                                const month = value.month();

                                const year = value.year();
                                const options = [];
                                for (let i = year - 10; i < year + 10; i += 1) {
                                    options.push(
                                        <Select.Option key={i} value={i} className="year-item">
                                            {i}
                                        </Select.Option>,
                                    );
                                }
                                return (
                                    <div style={{ padding: 8 }}>
                                        <Typography.Title level={4}>Päivystysvuorot</Typography.Title>
                                        <Row gutter={8}>

                                            <Col>
                                                <Select
                                                    size="small"
                                                    dropdownMatchSelectWidth={false}
                                                    className="my-year-select"
                                                    onChange={newYear => {
                                                        const now = value.clone().year(newYear);
                                                        onChange(now);
                                                    }}
                                                    value={String(year)}
                                                >
                                                    {options}
                                                </Select>
                                            </Col>
                                            <Col>
                                                <Select
                                                    size="small"
                                                    dropdownMatchSelectWidth={false}
                                                    value={String(month)}
                                                    onChange={selectedMonth => {
                                                        const newValue = value.clone();
                                                        newValue.month(parseInt(selectedMonth, 10));
                                                        onChange(newValue);
                                                    }}
                                                >
                                                    {monthOptions}
                                                </Select>
                                            </Col>
                                            <Col>
                                                {!isDesktop && <Badge color={'green'} text={'Vuorossa ei opettajaa'} />}
                                            </Col>
                                            <Col><Switch checked={omat} onChange={(checked) => setOmat(checked)} checkedChildren="omat" unCheckedChildren="kaikki" /></Col>
                                        </Row>
                                    </div>
                                );
                            }}
                        />
                    </Col>
                    }

                    {!showCal && <Col span={24} style={{ padding: 5 }}>

                        {//((auth && auth.user && auth.user.roles && auth.user.roles.includes('opettaja')) || (auth && auth.user && auth.user.roles && auth.user.roles.includes('hiihtoadmin'))) &&
                            <Button onClick={() => setShowCal(true)}>Takaisin kalenteriin</Button>
                        }


                        {//auth && auth.user && auth.user.roles && auth.user.roles.includes('hiihtoadmin') &&
                            <Card title={date.format("dddd, Do MMMM")}>
                                <List
                                    header={<div>Päivystysvuorot</div>}
                                    footer={<div></div>}
                                    dataSource={paivanVuorot}
                                    renderItem={(vuoro, i) => (
                                        <List.Item
                                        >
                                            <Text>{i + 1}. vuoro: &nbsp;&nbsp;{vuoro.alkaa} - {vuoro.paattyy}</Text>
                                            <Text>{moment(vuoro.paattyy, 'HH:mm').diff(moment(vuoro.alkaa, 'HH:mm'), 'hours')} tuntia</Text>
                                            <Select value={vuoro.opettaja && vuoro.opettaja.nimi} placeholder="Ope" onChange={(ope) => lisaaOpe(ope, vuoro.id)}>
                                                {opettajat.map(opettaja => (
                                                    opettaja && <Option key={opettaja.id}>{opettaja.nimi}</Option>
                                                ))}
                                                <Option key={1000} value={'poista'}>Poista opettaja</Option>
                                            </Select>
                                            <MinusCircleOutlined onClick={() => poistaVuoro(vuoro.id)} />
                                        </List.Item>
                                    )}
                                    style={{ fontSize: '1.1em' }}
                                    locale={{ emptyText: 'Päivälle ei ole lisätty vuoroja' }}
                                />

                                <Form
                                    form={form}
                                    layout="inline"
                                    requiredMark={false}
                                    onFinish={onFinish}

                                >
                                    <Form.Item name="alkaa" label="Alkaa" rules={[{ required: true }]} style={{ paddingTop: 5 }}>
                                        <Select>
                                            {['10:30', '11:30', '12:30', '13:30', '14:30', '15:30', '16:30', '17:30', '18:30', '19:30', '20:30'].map(aika =>
                                                <Option value={aika}>{aika}</Option>
                                            )}
                                        </Select>

                                    </Form.Item>
                                    <Form.Item name="paattyy" label="Päättyy" rules={[{ required: true }]} style={{ paddingTop: 5 }}>
                                        <Select>
                                            {['10:30', '11:30', '12:30', '13:30', '14:30', '15:30', '16:30', '17:30', '18:30', '19:30', '20:30'].map(aika =>
                                                <Option value={aika}>{aika}</Option>
                                            )}
                                        </Select>

                                    </Form.Item>
                                    <Form.Item name="opettajaId" style={{ paddingTop: 5 }}>
                                        <Select
                                            placeholder="Ope"
                                        >
                                            {opettajat.map(opettaja => (
                                                <Option key={opettaja.id} value={opettaja.id}>{opettaja.nimi}</Option>
                                            ))}
                                            <Option key={1000} value={''}>ope</Option>

                                        </Select>
                                    </Form.Item>
                                    <Form.Item style={{ paddingTop: 5 }}>
                                        <Button type="primary" htmlType="submit">
                                            Lisää vuoro
                                </Button>
                                    </Form.Item>
                                </Form>

                            </Card>
                        }

                        {//((auth && auth.user && auth.user.roles && auth.user.roles.includes('opettaja')) || (auth && auth.user && auth.user.roles && auth.user.roles.includes('hiihtoadmin'))) &&
                            <Card title={date.format("dddd, Do MMMM")}>
                                <List
                                    header={<div>Päivystysvuorot</div>}
                                    footer={<div></div>}
                                    dataSource={paivanVuorot}
                                    renderItem={(vuoro, i) => (
                                        <List.Item
                                        >
                                            <Text>{i + 1}. vuoro: &nbsp;&nbsp;{vuoro.alkaa} - {vuoro.paattyy}</Text>
                                            <Text>{moment(vuoro.paattyy, 'HH:mm').diff(moment(vuoro.alkaa, 'HH:mm'), 'hours')} tuntia</Text>
                                            <Text>{vuoro.opettaja && vuoro.opettaja.nimi}</Text>
                                            {!vuoro.opettaja && <Button onClick={() => otavuoro(vuoro, auth.user.id)}>Ota vuoro</Button>}
                                        </List.Item>
                                    )}
                                    style={{ fontSize: '1.1em' }}
                                    locale={{ emptyText: 'Päivälle ei ole lisätty vuoroja' }}
                                />
                            </Card>
                        }

                    </Col>
                    }

                    { //<Col span={12} style={{ padding: 5 }}>
                        //<Calendar fullscreen={false}  />
                        //</Col>
                    }
                </Row>

            </div>
        </ConfigProvider>
    )
}

export default Paivystysvuorot