import { Button, Card, Table, Typography, Space, Divider, Switch } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { url } from '../constants'
import { CheckOutlined, LeftCircleOutlined } from '@ant-design/icons';
import moment from 'moment'
import { useAuth } from '../auth/Auth';
import {
   
    Link
  } from "react-router-dom";

const { Text } = Typography

const HiihtotunnitOpe = () => {
    const [tunnit, setTunnit] = useState([])
    const [showAsiakas, setShowAsiakas] = useState(null)
    const [tulevat, setTulevat] = useState(true)
    const [tunnitToShow, setTunnitToShow] = useState([])

    const auth = useAuth()

    const columns = [
        {
            title: 'Asikas',
            dataIndex: 'asiakas',
            key: 'asiakas',
            render: asiakas => {

                if (asiakas) {
                    return (
                        <>
                            <CheckOutlined /> &nbsp;
                            {asiakas.lisaOppilaat && asiakas.lisaOppilaat.length + 1}
                        </>
                    )
                } else {
                    return ''
                }
            }
        },
        {
            title: 'Väline',
            dataIndex: 'valine',
            key: 'valine',
            render: valine => { return (valine === 1 ? 'Suksi' : 'Lauta') }
        },
        {
            title: 'Aika',
            dataIndex: 'aika',
            key: 'aika',
        },
        {
            title: 'Opettaja',
            dataIndex: 'opettaja',
            key: 'opettaja',
            render: opettaja => { return opettaja && opettaja.nimi }
        },

    ]

    useEffect(() => {
        setTunnitToShow(!tulevat ? tunnit : tunnit.filter(tunti => moment(tunti.aika).isAfter(moment())))
        console.log('tulevat: ', tulevat)
    }, [tulevat])

    useEffect(async () => {
        const tunnit = await axios(url + '/maksetut')
        const t = tunnit.data
        console.log('t: ', t)
        setTunnit(t)
        setTunnitToShow(t.filter(tunti => moment(tunti.aika).isAfter(moment())))
        //setTunnit([{ aika: '2021-03-28 10:30', valine: 1, asiakas: { etunimi: 'Jaska', sukunimi: 'Jokunen', valineet: true, taitotaso: 2, lisaOppilaat: [{ etunimi: 'Riikka', valineetLisaOppilas: true, taitotaso: 1 }] }, opettaja: { nimi: 'Pasi' } }, { aika: '2021-02-28 10:30', valine: 1, asiakas: { etunimi: 'Matti', sukunimi: 'Mäkinen', valineet: true, lisaOppilaat: [{ etunimi: 'Riikka', valineetLisaOppilas: true }] }, opettaja: { nimi: 'Pasi' } }])
        //setTunnitToShow([{ aika: '2021-03-28 10:30', valine: 1, asiakas: { etunimi: 'Jaska', sukunimi: 'Jokunen', valineet: true, taitotaso: 2, lisaOppilaat: [{ etunimi: 'Riikka', valineetLisaOppilas: true, taitotaso: 1 }] }, opettaja: { nimi: 'Pasi' } }, { aika: '2021-02-28 10:30', valine: 1, asiakas: { etunimi: 'Matti', sukunimi: 'Mäkinen', valineet: true, lisaOppilaat: [{ etunimi: 'Riikka', valineetLisaOppilas: true }] }, opettaja: { nimi: 'Pasi' } }].filter(tunti => moment(tunti.aika).isAfter(moment())))
        console.log('moment: ', moment('2021-03-28 10:30'))
        console.log('moment: ', moment())
        console.log(moment('2021-02-28 10:30').isAfter(moment()))
    }, [])

    return (
        <div style={{ marginTop: 30, flex: '1 0 auto' }}>
            {!showAsiakas && <Button style={{marginRight: 50}} type="primary"><Link to="/"><LeftCircleOutlined /> Takaisin etusivulle</Link></Button>}
            {!showAsiakas && <Switch onClick={(checked) => setTulevat(!checked)} checkedChildren="kaikki" unCheckedChildren="tulevat" />}

            {!showAsiakas &&

            <>
                <Table dataSource={tunnitToShow}
                    columns={columns}
                    onRow={(record, rowindex) => {
                        return {
                            onClick: () => { setShowAsiakas(record) }
                        }
                    }} />
                    </>
            }
            {showAsiakas &&
                <>
                    <Button onClick={() => setShowAsiakas(false)}>Takaisin listaukseen</Button>
                    <Card title={moment(showAsiakas.aika).format('[klo] HH:mm [-] dddd, MMMM Do YYYY')}>
                        <Space direction="vertical">
                            <Text>Etunimi: {showAsiakas.asiakas.etunimi}</Text>
                            <Text>Sukunimi: {showAsiakas.asiakas.sukunimi}</Text>
                            <Text>Puhelin: {showAsiakas.asiakas.puhelin}</Text>
                            <Text>Sähköposti: {showAsiakas.asiakas.sahkoposti}</Text>
                            {showAsiakas.asiakas.ika === 1 && <Text>Ikä: lapsi alle 6v</Text>}
                            {showAsiakas.asiakas.ika === 2 && <Text>Ikä: lapsi 6-11v</Text>}
                            {showAsiakas.asiakas.ika === 3 && <Text>Ikä: nuori</Text>}
                            {showAsiakas.asiakas.ika === 4 && <Text>Ikä: aikuinen</Text>}
                            {showAsiakas.asiakas.taitotaso === 1 && <Text>Taitotaso: Ensikertalainen</Text>}
                            {showAsiakas.asiakas.taitotaso === 2 && <Text>Taitotaso: Aloitteleva</Text>}
                            {showAsiakas.asiakas.taitotaso === 3 && <Text>Taitotaso: Kokenut</Text>}
                            {showAsiakas.asiakas.valineet &&
                                 <>
                                 <Text>Vuokraan myös välineet</Text>
                                 <Text>Pituus: {showAsiakas.asiakas.pituus}</Text>
                                 <Text>Paino: {showAsiakas.asiakas.paino}</Text>
                                 <Text>Kengän koko: {showAsiakas.asiakas.kenkä}</Text>
                             </>
                            }
                            
                            {showAsiakas && showAsiakas.asiakas && showAsiakas.asiakas.lisaOppilaat &&
                                showAsiakas.asiakas.lisaOppilaat.map(oppilas =>
                                    <>
                                        <Divider />
                                        <Text>Etunimi: {oppilas.etunimi}</Text>
                                        {oppilas.ika === 1 && <Text>Ikä: lapsi alle 6v</Text>}
                                        {oppilas.ika === 2 && <Text>Ikä: lapsi 6-11v</Text>}
                                        {oppilas.ika === 3 && <Text>Ikä: nuori</Text>}
                                        {oppilas.ika === 4 && <Text>Ikä: aikuinen</Text>}

                                        {oppilas.taitotaso === 1 && <Text>Taitotaso: Ensikertalainen</Text>}
                                        {oppilas.taitotaso === 2 && <Text>Taitotaso: Aloitteleva</Text>}
                                        {oppilas.taitotaso === 3 && <Text>Taitotaso: Kokenut</Text>}

                                        {oppilas.valineetLisaOppilas &&
                                            <>
                                                <Text>Vuokraan myös välineet</Text>
                                                <Text>Pituus: {oppilas.pituus}</Text>
                                                <Text>Paino: {oppilas.paino}</Text>
                                                <Text>Kengän koko: {oppilas.kenkä}</Text>
                                            </>
                                        }

                                    </>
                                )
                            }
                        </Space>
                    </Card>
                </>
            }
        </div>
    )

}

export default HiihtotunnitOpe