import { useContext, createContext, useState } from "react";
import axios from 'axios'
import { url, home } from '../constants'

export const authContext = createContext();

export function useAuth() {
    return useContext(authContext);
}

export function useProvideAuth() {

    const [user, setUser] = useState()
    
     const login = async (credentials, cb) => {
        console.log(credentials);
        var bodyFormData = new FormData();
        bodyFormData.append('username', credentials.username);
        bodyFormData.append('password', credentials.password);
        const res = await axios.post(url + '/login', bodyFormData);
        setUser(res.data);
        window.location.href = "/"
        cb();
     }

     const logout = async () => {
    
         await axios(url + '/logout');
         window.location.replace(home)

     }

     const addUser = (u) => {
         setUser(u)
     }

    return {
        user,
        login,
        logout,
        addUser
    }
}