import axios from 'axios'
import { useEffect, useState } from 'react'
import { Button, Input, Form, Select, Space, Row, Col, Divider, Typography, Card, Calendar, Breadcrumb } from 'antd'
import { MinusCircleOutlined, PlusOutlined, HomeOutlined } from "@ant-design/icons";
import moment from 'moment'
import { ConfigProvider } from 'antd';
import fi_FI from 'antd/lib/locale/fi_FI'

import vismapay from 'visma-pay'

import op from '../../kuvat/pankkipainikkeet/osuuspankki.png'
import nordea from '../../kuvat/pankkipainikkeet/nordea.png'
import handelsbanken from '../../kuvat/pankkipainikkeet/handelsbanken.png'
import danskebank from '../../kuvat/pankkipainikkeet/danskebank.png'
import aktia from '../../kuvat/pankkipainikkeet/aktia.png'
import omasaastopankki from '../../kuvat/pankkipainikkeet/omasaastopankki.png'
import paikallisosuuspankki from '../../kuvat/pankkipainikkeet/paikallisosuuspankki.png'
import saastopankki from '../../kuvat/pankkipainikkeet/saastopankki.png'
import alandsbanken from '../../kuvat/pankkipainikkeet/alandsbanken.png'
import spankki from '../../kuvat/pankkipainikkeet/spankki.png'
import mastercard from '../../kuvat/pankkipainikkeet/mastercard.png'
import visa from '../../kuvat/pankkipainikkeet/visa.png'
import mobilepay from '../../kuvat/pankkipainikkeet/mobilepay.png'
import pivo from '../../kuvat/pankkipainikkeet/pivo.png'
import siirto from '../../kuvat/pankkipainikkeet/siirto.png'
import masterpass from '../../kuvat/pankkipainikkeet/masterpass.png'

import { url } from '../constants'

import Checkbox from 'antd/lib/checkbox/Checkbox';

import 'moment/locale/fi'  // without this line it didn't work
moment.locale('fi')

const { Title, Text } = Typography;

vismapay.setPrivateKey('cc56675f698c01c44cf47d4ea4018134');
vismapay.setApiKey('1198584cb7b62a7e6c9b8059cd4d5ce6629b9');

const { Option } = Select;

const OstaTunti = () => {
    const [tunnit, setTunnit] = useState([])
    const [suksitunnit, setSuksitunnit] = useState([])
    const [lautatunnit, setLautatunnit] = useState([])
    const [varausnumero, setVaraunumero] = useState();
    const [summa, setSumma] = useState(4000)
    const [maksutapa, setMaksutapa] = useState();
    const [varattu, setVarattu] = useState()
    const [valineet, setValineet] = useState(false)
    const [valineetLisaOppilaat, setValineetLisaOppilaat] = useState([])
    const [formValues, setFormValues] = useState({})
    const [hintaHiihtokoulu, setHintaHiihtokoulu] = useState()
    const [hintaHiihtokouluPerAsiakas, setHintaHiihtokouluPerAsiakas] = useState(40)
    const [hintaValineet, sewtHintaValineet] = useState(0)
    const [tunti, setTunti] = useState()
    const [lautaTunti, setLautaTunti] = useState()
    const [paivanSuksiTunnit, setPaivanSuksiTunnit] = useState([])

    useEffect(() => {
        console.log('formValues in useEffect: ', formValues)
        console.log(formValues.lisaOppilaat)
        console.log((formValues.lisaOppilaat !== undefined && formValues.lisaOppilaat.filter(lisaOppilas => lisaOppilas === undefined || lisaOppilas.valineetLisaOppilas !== undefined).length))
    })

    useEffect(() => {
        axios.get(url + '/ostettavissa')
            .then(res => setTunnit(res.data))
    }, [])

    useEffect(() => {
        console.log(url)
        axios.get(url + '/vapaatsuksitunnit')
            .then(res => setSuksitunnit(res.data))
    }, [])

    useEffect(() => {
        axios.get(url + '/vapaatlautatunnit')
            .then(res => setLautatunnit(res.data))
    }, [])

    useEffect(() => {
        setHintaHiihtokoulu(
            formValues.lisaOppilaat && formValues.lisaOppilaat.length > 0 ? ((formValues.lisaOppilaat && formValues.lisaOppilaat.length + 1) * 30) : 40
        )
        setHintaHiihtokouluPerAsiakas(
            (formValues.lisaOppilaat && formValues.lisaOppilaat.length > 0) ? 30 : 40
        )
    }, [formValues.lisaOppilaat && formValues.lisaOppilaat.length])


    const valuesChanged = (value, values) => {
        setFormValues(values)
        console.log('values in valuesChanged: ', values)
    }

    const layout = {
        labelCol: {
            span: 12,
        },
        wrapperCol: {
            span: 14,
        },
    };
    const tailLayout = {
        wrapperCol: {
            offset: 16,
            span: 8,
        },
    };

    const onFinish = async (values) => {
        values['pankki'] = values.pankki
        console.log('Success:', values);

        const token = await axios.post(url + '/varausvoimassa/' + varausnumero, values)

        window.location.href = "https://www.vismapay.com/pbwapi/token/" + token.data

        //axios('https://www.vismapay.com/pbwapi/token/' + token)

        /*
        const voimassa = axios('https://pasivirta.fi/varausvoimassa/' + varausnumero + "/?tiedot=" + values.nimi)
        console.log('voimassa: ', voimassa)
        voimassa.then(voimassa => {
            if (voimassa.data === true) {
                maksu()
            }

        })
        */

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const varaa = (id, valine) => {
        axios(url + '/varaatunti/' + id + "/" + valine)
            .then(res => {
                if (res.data === 'Tunti ehdittiin varaamaan') {
                    setVaraunumero(null)
                    console.log(res.data)
                    setVarattu(res.data)
                } else {
                    setVaraunumero(res.data)
                    console.log(res.data)
                    setTunti(tunnit.filter(tunti => tunti.id === id)[0])
                    console.log(tunnit.filter(tunti => tunti.id === id))
                }
            });
    }

    const onSelect = (d) => {
        setPaivanSuksiTunnit(
            tunnit.filter(tunti => tunti.opettaja.suksi && (moment(d).format('YYYY-MM-DD') === moment(tunti.aika).format('YYYY-MM-DD')))
        )
    }


    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 8,
                offset: 8,
            },
        },
    };



    return (
        <ConfigProvider locale={fi_FI}>
            <div style={{ flex: '1 0 auto', backgroundColor: 'white' }}>
                {varattu ? <p>{varattu}</p> : ''}

                {
                    !varausnumero ?
                        (

                            <>

                                <Card title="Suksitunnit" style={{ margin: 30 }}>

                                    <Calendar
                                        fullscreen={false}
                                        onSelect={(d) => onSelect(d)}
                                        headerRender={({ value, onChange, }) => {
                                            const start = 0;
                                            const end = 12;
                                            const monthOptions = [];

                                            const current = value.clone();
                                            const localeData = value.localeData();
                                            const months = [];
                                            for (let i = 0; i < 12; i++) {
                                                current.month(i);
                                                months.push(localeData.monthsShort(current));
                                            }

                                            for (let index = start; index < end; index++) {
                                                monthOptions.push(
                                                    <Select.Option className="month-item" key={`${index}`}>
                                                        {months[index]}
                                                    </Select.Option>,
                                                );
                                            }
                                            const month = value.month();

                                            const year = value.year();
                                            const options = [];
                                            for (let i = year - 10; i < year + 10; i += 1) {
                                                options.push(
                                                    <Select.Option key={i} value={i} className="year-item">
                                                        {i}
                                                    </Select.Option>,
                                                );
                                            }
                                            return (
                                                <div style={{ padding: 8 }}>
                                                    <Typography.Title level={4}>Laskettelu suksilla</Typography.Title>
                                                    <Row gutter={8}>

                                                        <Col>
                                                            <Select
                                                                size="small"
                                                                dropdownMatchSelectWidth={false}
                                                                className="my-year-select"
                                                                onChange={newYear => {
                                                                    const now = value.clone().year(newYear);
                                                                    onChange(now);
                                                                }}
                                                                value={String(year)}
                                                            >
                                                                {options}
                                                            </Select>
                                                        </Col>
                                                        <Col>
                                                            <Select
                                                                size="small"
                                                                dropdownMatchSelectWidth={false}
                                                                value={String(month)}
                                                                onChange={selectedMonth => {
                                                                    const newValue = value.clone();
                                                                    newValue.month(parseInt(selectedMonth, 10));
                                                                    onChange(newValue);
                                                                }}
                                                            >
                                                                {monthOptions}
                                                            </Select>
                                                        </Col>


                                                    </Row>
                                                </div>
                                            );
                                        }}
                                    />

                                    {

                                        paivanSuksiTunnit.sort((a, b) => { return moment(a.aika).isBefore(b.aika) ? -1 : 1 }).map(suksi => {
                                            return <Button key={suksi.id} style={{ margin: '5px' }} onClick={() => varaa(suksi.id, 1)}>{moment(suksi.aika).format("dddd, MMMM Do HH:mm")}</Button>
                                        })}
                                </Card>

                                <Divider />

                                <Card title="Lautatunnit" style={{ margin: 30 }}>
                                    {lautatunnit.map(lauta => {
                                        return <Button key={lauta.id} style={{ margin: '5px' }} onClick={() => varaa(lauta.id, 2)}>{moment(lauta.aika).format("dddd, MMMM Do HH:mm")}</Button>
                                    })}
                                </Card>



                                {tunnit.map(tunti => {
                                    //return <Button key={tunti.id} style={{ margin: '5px' }} onClick={() => varaa(tunti.id)}>{moment(tunti.aika).format("dddd, MMMM Do hh:mm")}</Button>
                                })}

                            </>
                        )

                        :
                        <Form name="hiihtotunti" onFinish={onFinish} autoComplete="off" requiredMark={false}
                            {...formItemLayout} style={{ marginTop: 20 }} onValuesChange={(value, values) => valuesChanged(value, values)}
                            initialValues={{
                                valineet: false,
                                pituus: '',
                                paino: '',
                                kenka: ''

                            }}

                        >
                            <Row>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>

                                    <Col offset={8} style={{marginBottom:30}}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">
                                            <HomeOutlined />
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item href="">
                                            <span>hiihtotunnit</span>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>Osta tunti</Breadcrumb.Item>
                                    </Breadcrumb>
                                    </Col>
                                    
                                    <Form.Item
                                        name="etunimi"
                                        fieldKey="etunimi"
                                        rules={[{ required: true, message: "Kirjoita etunimi" }]}
                                        label="Etunimi"
                                    >
                                        <Input placeholder="Etunimi" />
                                    </Form.Item>

                                    <Form.Item
                                        name="sukunimi"
                                        fieldKey="sukunimi"
                                        rules={[{ required: true, message: "Kirjoita etunimi" }]}
                                        label="Sukunimi"
                                    >
                                        <Input placeholder="Sukunimi" />
                                    </Form.Item>

                                    <Form.Item
                                        name="puhelin"
                                        fieldKey="puhelin"
                                        rules={[{ required: true, message: "Kirjoita etunimi" }]}
                                        label="Puhelinnumero"
                                    >
                                        <Input placeholder="Puhelinnumero" />
                                    </Form.Item>

                                    <Form.Item
                                        name="sahkoposti"
                                        fieldKey="sahkoposti"
                                        rules={[{ required: true, message: "Kirjoita etunimi" }]}
                                        label="Sähköposti"
                                    >
                                        <Input placeholder="Sähköposti" />
                                    </Form.Item>

                                    <Form.Item
                                        name="ika"
                                        fieldKey="ika"
                                        rules={[{ required: true }]}
                                        label="Ikä"
                                    >
                                        <Select>
                                            <Option value="1">Lapsi (alle 6 v)</Option>
                                            <Option value="2">Lapsi (6-11 v)</Option>
                                            <Option value="3">Nuori</Option>
                                            <Option value="4">Aikuinen</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="taitotaso"
                                        fieldKey="taitotaso"
                                        rules={[{ required: true }]}
                                        label="Taitotaso"
                                    >
                                        <Select>
                                            <Option value="1">Ensikertalainen</Option>
                                            <Option value="2">Aloitteleva</Option>
                                            <Option value="3">Kokenut</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="valineet"
                                        fieldKey="valineet"
                                        {...tailFormItemLayout}
                                        valuePropName="checked"
                                    >
                                        <Checkbox style={{ marginTop: -30 }} checked={valineet} >Vuokraan myös välineet</Checkbox>
                                    </Form.Item>

                                    <Form.Item
                                        label="Paino"
                                        name="paino"
                                        fieldKey="paino"
                                        rules={[{ required: valineet ? true : false, message: "Kirjoita paino" }]}
                                        hidden={!formValues.valineet}
                                    >
                                        <Input placeholder="Paino" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Pituus"
                                        name="pituus"
                                        fieldKey="pituus"
                                        rules={[{ required: valineet ? true : false, message: "Kirjoita pituus" }]}
                                        hidden={!formValues.valineet}
                                    >
                                        <Input placeholder="Pituus" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Kengän koko"
                                        name="kenka"
                                        fieldKey="kenka"
                                        rules={[{ required: valineet ? true : false, message: "Kirjoita kengän koko" }]}
                                        hidden={!formValues.valineet}
                                    >
                                        <Input placeholder="Kengän koko" />
                                    </Form.Item>

                                    <Form.List
                                        name="lisaOppilaat"

                                    >


                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map((field, i) => (

                                                    <>
                                                        <Divider />
                                                        <Col sm={{ offset: 4 }}>
                                                            <Title level={3}>Oppilas {i + 2} <MinusCircleOutlined onClick={() => remove(field.name)} /></Title>

                                                        </Col>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, "etunimi"]}
                                                            fieldKey={[field.fieldKey, "etunimi"]}
                                                            rules={[{ required: true, message: "Kirjoita etunimi" }]}
                                                            label="Etunimi"
                                                        >
                                                            <Input placeholder="Etunimi" />
                                                        </Form.Item>

                                                        <Form.Item
                                                            name={[field.name, "ika"]}
                                                            fieldKey={[field.fieldKey, "ika"]}
                                                            rules={[{ required: true }]}
                                                            label="Ikä"
                                                        >
                                                            <Select>
                                                                <Option value="1">Lapsi (alle 6 v)</Option>
                                                                <Option value="2">Lapsi (6-11 v)</Option>
                                                                <Option value="3">Nuori</Option>
                                                                <Option value="4">Aikuinen</Option>
                                                            </Select>
                                                        </Form.Item>

                                                        <Form.Item
                                                            name={[field.name, "taitotaso"]}
                                                            fieldKey={[field.fieldKey, "taitotaso"]}
                                                            rules={[{ required: true }]}
                                                            label="Taitotaso"
                                                            initialValue={"Lapsi"}
                                                        >
                                                            <Select>
                                                                <Option value="1">Ensikertalainen</Option>
                                                                <Option value="2">Aloitteleva</Option>
                                                                <Option value="3">Kokenut</Option>
                                                            </Select>
                                                        </Form.Item>

                                                        <Form.Item
                                                            name={[field.name, "valineetLisaOppilas"]}
                                                            {...tailFormItemLayout}
                                                            valuePropName="checked"
                                                            initialValue={false}
                                                        >
                                                            <Checkbox checked={valineetLisaOppilaat[i]}>Vuokraan myös välineet</Checkbox>
                                                        </Form.Item>

                                                        <Form.Item
                                                            noStyle
                                                            shouldUpdate={(prevValues, currentValues) => prevValues.valineetLisaOppilaat !== currentValues.valineetLisaOppilaat}
                                                        >
                                                            {({ }) => {
                                                                return formValues.lisaOppilaat[i] !== undefined && formValues.lisaOppilaat[i].valineetLisaOppilas ? (

                                                                    <>
                                                                        <Form.Item
                                                                            label="Paino"
                                                                            name={[field.name, "paino"]}
                                                                            fieldKey={[field.fieldKey, "paino"]}
                                                                            rules={[{ required: true, message: "Kirjoita paino" }]}
                                                                            initialValue={''}
                                                                        >
                                                                            <Input placeholder="Paino" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Pituus"
                                                                            name={[field.name, "pituus"]}
                                                                            fieldKey={[field.fieldKey, "pituus"]}
                                                                            rules={[{ required: true, message: "Kirjoita pituus" }]}
                                                                            initialValue={''}
                                                                        >
                                                                            <Input placeholder="Pituus" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Kengän koko"
                                                                            name={[field.name, "kenka"]}
                                                                            fieldKey={[field.fieldKey, "kenka"]}
                                                                            rules={[{ required: true, message: "Kirjoita kenkän koko" }]}
                                                                            initialValue={''}
                                                                        >
                                                                            <Input placeholder="Pituus" />
                                                                        </Form.Item>

                                                                    </>

                                                                ) : null;
                                                            }}

                                                        </Form.Item>

                                                    </>

                                                ))}
                                                <Form.Item
                                                    {...tailFormItemLayout}
                                                >
                                                    <Button

                                                        type="dashed"
                                                        onClick={
                                                            () => {
                                                                add()
                                                            }}
                                                        block icon={<PlusOutlined />}
                                                    >Lisää oppilas</Button>
                                                </Form.Item>
                                            </>
                                        )}

                                    </Form.List>



                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Card title={"Ostos - " + (tunti ? 'Hiihtotunti suksille - ' : '') + (lautaTunti ? 'Hiihtotunti laudalle - ' : '') + (tunti && moment(tunti.aika).format("dddd, Do MMMM [klo:] HH:mm"))} style={{ margin: 20 }}>
                                        <Row gutter={[16, 24]} style={{ marginBottom: 35 }}>
                                            <Col span={6}>Hiihtokoulu: </Col>
                                            <Col span={6}>
                                                <Text strong>{1 + (formValues.lisaOppilaat !== undefined && formValues.lisaOppilaat.filter(lisaOppilas => lisaOppilas === undefined || lisaOppilas.valineetLisaOppilas !== undefined).length)} </Text>
                                                <Text strong>{(formValues.lisaOppilaat !== undefined && formValues.lisaOppilaat.filter(lisaOppilas => lisaOppilas === undefined || lisaOppilas.valineetLisaOppilas !== undefined).length) == 0 ? "oppilas" : 'oppilasta'} </Text>
                                            </Col>
                                            <Col span={6}>
                                                <Text strong >{"á "} {formValues.lisaOppilaat === undefined ? 40 : (formValues.lisaOppilaat !== undefined && formValues.lisaOppilaat.filter(lisaOppilas => lisaOppilas === undefined || lisaOppilas.valineetLisaOppilas !== undefined).length) === 0 ? 40 : 30}€</Text>
                                            </Col>
                                            <Col span={6}>
                                                <Text strong >{formValues.lisaOppilaat === undefined ? 40 : (formValues.lisaOppilaat !== undefined && formValues.lisaOppilaat.filter(lisaOppilas => lisaOppilas === undefined || lisaOppilas.valineetLisaOppilas !== undefined).length) === 0 ? 40 : 30 * (formValues.lisaOppilaat !== undefined && formValues.lisaOppilaat.filter(lisaOppilas => lisaOppilas === undefined || lisaOppilas.valineetLisaOppilas !== undefined).length) + 30}€</Text>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 24]} style={{ marginBottom: 35 }}>
                                            <Col span={6}>Välinevuokra: </Col>
                                            <Col span={6}>

                                                {(formValues.valineet ? 1 : 0) + (formValues.lisaOppilaat !== undefined && formValues.lisaOppilaat.filter(lisaOppilas => lisaOppilas && lisaOppilas.valineetLisaOppilas === true).length)} {((formValues.valineet ? 1 : 0) + (formValues.lisaOppilaat !== undefined && formValues.lisaOppilaat.filter(lisaOppilas => lisaOppilas && lisaOppilas.valineetLisaOppilas === true).length)) === 1 ? 'setti' : 'settiä'}

                                            </Col>
                                            <Col span={6}>{"á 10€"}</Col>

                                            <Col span={6}>{((formValues.valineet ? 1 : 0) + (formValues.lisaOppilaat !== undefined && formValues.lisaOppilaat.filter(lisaoppilas => lisaoppilas && (lisaoppilas.valineetLisaOppilas === true)).length)) * 10}€

                                        </Col>
                                        </Row>
                                        <Row>
                                            <Col offset={12}>Yhteensä: </Col>
                                            <Col offset={3}>{(((formValues.valineet ? 1 : 0) + (formValues.lisaOppilaat !== undefined && formValues.lisaOppilaat.filter(lisaoppilas => lisaoppilas && (lisaoppilas.valineetLisaOppilas === true)).length)) * 10) + ((formValues.lisaOppilaat !== undefined && formValues.lisaOppilaat.filter(lisaOppilas => lisaOppilas === undefined || lisaOppilas.valineetLisaOppilas !== undefined).length) * 30 + 30)}€</Col>
                                        </Row>

                                        <p style={{ marginBottom: -1 }}>Korttimaksu</p>
                                        <Row gutter={[10, 10]} style={{ marginBottom: 15 }}>
                                            <img src={visa} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'visa' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('visa')} />
                                            <img src={mastercard} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'mastercard' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('mastercard')} />
                                        </Row>

                                        <p style={{ marginBottom: -1 }}>Lompakkopalvelut</p>
                                        <Row gutter={[10, 10]} style={{ marginBottom: 15 }}>
                                            <img src={mobilepay} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'mobilepay' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('mobilepay')} />
                                            <img src={pivo} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'pivo' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('pivo')} />
                                            <img src={siirto} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'siirto' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('siirto')} />
                                            <img src={masterpass} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'masterpass' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('masterpass')} />
                                        </Row>

                                        <p style={{ marginBottom: -1 }}>Verkkopankkimaksu</p>
                                        <Row gutter={[10, 10]} style={{ marginBottom: 15 }}>
                                            <img src={op} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'osuuspankki' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('osuuspankki')} />
                                            <img src={nordea} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'nordea' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('nordea')} />
                                            <img src={handelsbanken} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'handelsbanken' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('handelsbanken')} />
                                            <img src={danskebank} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'danskebank' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('danskebank')} />
                                            <img src={aktia} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'aktia' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('aktia')} />
                                            <img src={omasaastopankki} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'omasaastopankki' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('omasaastopankki')} />
                                            <img src={paikallisosuuspankki} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'paikallisosuuspankki' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('paikallisosuuspankki')} />
                                            <img src={saastopankki} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'saastopankki' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('saastopankki')} />
                                            <img src={alandsbanken} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'alandsbanken' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('alandsbanken')} />
                                            <img src={spankki} style={{ marginRight: 4, maxWidth: 100, border: maksutapa === 'spankki' ? '3px solid gray' : '1px solid black' }} onClick={() => setMaksutapa('spankki')} />
                                        </Row>
                                        <Row gutter={[16, 24]}>
                                            <Form.Item
                                            >
                                                <Button type="primary" htmlType="submit">
                                                    Maksa
                                            </Button>
                                            </Form.Item>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                }

            </div>
        </ConfigProvider>
    )
}

export default OstaTunti